import styled from "styled-components";
import { IGatsbyImageData, GatsbyImage, getImage } from "gatsby-plugin-image";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import * as SVGIcons from "../../utils/svg";
import FlavorIcon from "./FlavorIcon";
import { Product } from "../../utils/productTypes";

import GridRow from "../grid/GridRow";
import GridColumn from "../grid/GridColumn";
import { MagicSVGImage } from "../MagicSVGImage";

export type ImageType = {
  gatsbyImageData: IGatsbyImageData;
  description: string;
};

export type HowToUsePanelProps = {
  selectedProduct: Product;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2, 32px);
  margin-top: var(--spacing-2, 32px);

  .image-container {
    aspect-ratio: 327 / 229;

    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media(min-width: 750px) {
    .image-container {
      aspect-ratio: 391 / 274;
    }
  }
`;

const Instructions = styled.span.attrs({
  className: "typography-lead1",
})`
  font-size: var(--spacing-1_5, 24px);

  p {
    margin-bottom: unset;
  }

  @media (min-width: 1024px) {
    font-size: var(--spacing-2, 32px);
  }
`;

const ScentTab = styled.div`
  padding: var(--spacing-1, 16px);
  color: var(--indigo-blue);
  background-color: var(--yellow-ochre-40);
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1, 16px);

  .typography-body2 {
    font-family: var(--font-circular);
  }
`;

const FlavorIconExtend = styled(FlavorIcon)`
  position: unset;

  img {
    width: 56px;
    height: 56px;
  }
`;

const HowToUsePanel = ({ selectedProduct }: HowToUsePanelProps) => {
  const usage = selectedProduct?.howToUse;
  const parsedInstructions =
    usage?.instructions?.raw &&
    documentToHtmlString(JSON.parse(usage?.instructions?.raw));

  const showTooltipIcon = () => {
    switch (usage?.tooltip?.type) {
      case "scent":
        if (selectedProduct.sku === "SLPC") {
          return (
            <MagicSVGImage
              width={56}
              height={56}
              alt={`${SVGIcons.EssenceVanilla}`}
              src={SVGIcons.EssenceVanilla}
            />
          );
        } else {
          return <FlavorIconExtend className={""} sku={selectedProduct.sku} />;
        }
      case "protein":
        return (
          <MagicSVGImage
            width={56}
            height={56}
            alt={`${SVGIcons.EssenceVanilla}`}
            src={SVGIcons.EssenceVanilla}
          />
        );
      case "fertility":
        return (
          <MagicSVGImage
            width={56}
            height={56}
            alt={`${SVGIcons.FlavorLemon}`}
            src={SVGIcons.FlavorLemon}
          />
        );
      default:
        return null;
    }
  };

  const showTooltip = usage?.tooltip;

  return (
    <GridRow>
      <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
        <ContentContainer>
          <Instructions
            dangerouslySetInnerHTML={{
              __html: parsedInstructions,
            }}
          />
          {usage?.image && (
            <div className="image-container">
              <GatsbyImage
                image={usage?.image?.gatsbyImageData}
                alt={usage?.image?.description}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          )}
          {showTooltip && (
            <ScentTab>
              <span
                className="tooltip-content typography-label2"
                dangerouslySetInnerHTML={{
                  __html: usage.tooltip.content.replaceAll(
                    "<span>",
                    '<span class="typography-body2">',
                  ),
                }}
              />
              {showTooltipIcon()}
            </ScentTab>
          )}
        </ContentContainer>
      </GridColumn>
    </GridRow>
  );
};

export default HowToUsePanel;
